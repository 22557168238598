@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
    font-family:Calibri;
    src: url('../font/Calibri\ Light.ttf');
    font-weight: 300;
}
@font-face {
    font-family:Calibri;
    src: url('../font/Calibri\ Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family:Calibri;
    src: url('../font/Calibri\ Bold.TTF');
    font-weight: 600;
}


:root {
  --primary-color: #4C4C4C;
  --secondary-color: #0768A3;
  --dark-gray:#e0e0e0;
  --white:#FFFFFF;
  --black:#000;
  --primary-font: Calibri;
  --secondary-font: Poppins;
}
.heading1{
    font-size: 72px;
    line-height: 80px;
    color: var(--primary-color);
    font-weight: 600;
}
.heading2{
    font-size: 32px;
    line-height: 36x;    
}
.heading3{
    font-size: 22px;
    line-height: 26x;    
    color: var(--primary-color);
}
h1,
h2,
h3,
h4,
h5,
h6,
span {
    margin: 0;
}
input::placeholder {
    color: #A9A9A9;

}
::-ms-input-placeholder { 
    color: #A9A9A9;

  }  
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
a {
    text-decoration: none!important;
    color: inherit!important;
}
a:hover {
    color: inherit;
}
.btns {    
    border: 2px solid var(--white);
    border-radius: 6px;
    display:inline-block;
    height:54px;
    line-height:60px;
    overflow:hidden;
    position:relative;
    text-align:center;
    background-color:transparent;
    color:var(--white);   
    transition:0.3s;

}
.btns:hover { 
    background-color: var(--secondary-color);
    border: 2px solid transparent;
}
.btns .label-up {
    display:block;
    margin:0px 80px;
    height:100%;
    position:relative;
    top:24%;
    transition:0.3s;
    font-size: 24px;
    line-height: 26px;
    color: var(--white);
    font-family: var(--primary-font);
    font-weight: 600;
  }
  .btns:hover .label-up {
    top:-76%;
    background-color: var(--secondary-color);
  }
  @media screen and (min-width: 1200px) and (max-width: 1399px){
    .btns {
        height: 48px;
    }
  }
  @media screen and (max-width: 767px){
    .btns {
        height: 47px;
    }
    .btns .label-up {
        font-size: 20px;
        line-height: 25px;
        margin: 0px 42px;
    }
  }