h1{
    color: aqua;
}







@media screen and (min-width: 1500px) {
  .container {
    max-width: 1420px;
  }
}
@media screen and (min-width: 1600px) {
  .container {
    max-width: 1520px;
  }
}
@media screen and (min-width: 1700px) {
    .container {
      max-width: 1640px;
    }
  }
  
