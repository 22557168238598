header{
    position: absolute;
    top: 0;
    z-index: 1;
    background: transparent;
    width: 100%;
}
.navbar-nav li {
    font-size: 24px;
    line-height: 30px;
    color: var(--white);
    font-family: var(--primary-font);
    font-weight: 500;
    position: relative;    
    display: flex;
    align-items: center;
    transition: all 0.1s ease-in-out;
    padding-left: 30px;
}
.navbar-nav li:hover {
    color: var(--secondary-color);    
}
.navbar-nav li:hover .dot {
    visibility: visible;
    opacity: 1;
}
.navbar-nav li a {
    transform: translateX(-36px);
    transition: all 0.2s ease-in-out;
}
.navbar-nav li:hover a {
    transform: translateX(-20px);
}

.navbar-nav li .dot {
    width: 7px;
    height: 7px;
    background: var(--secondary-color);
    border-radius: 100%;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 0;

}
header .btns .label-up {
    margin: 0px 30px;
}
.navbar-toggler {
    border:none;
}
.navbar-toggler span {
    width: 33px;
    height: 4px;
    background: var(--white);
    display: block;
    margin-bottom: 7px;
    border-radius: 7px;;
}
.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: unset;
}
@media screen and (max-width:991px){
    .navbar{
      justify-content: space-evenly!important;
      flex-direction: row-reverse;
    }
    .navbar-toggler, .navbar-brand {
      margin-right: auto;
    }
    .navbar-nav li {
      color: var(--black);
      border-bottom: 1px solid #000;
    }
    .navbar-collapse {
      background: var(--white);
      padding: 30px;
      
    }
  }
@media screen and (max-width: 767px){ 
    .navbar-brand img {
        width: 188px;
    }
    .navbar-nav li {
        font-size: 22px;
        line-height: 25px;
      }
}