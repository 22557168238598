.full_bg {
  position: relative;
    height: 100vh;
    
  }
 html {
  scroll-behavior: smooth;
 }
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 span {
    color: var(--white);
  }
  .react-parallax-bgimage{
    height: 100% !important;
    width: 100%!important;
  }  
  p{
    font-size: 22px;
    line-height: 35px;
    color: #D7D7D7;
    font-family: var(--secondary-font);
  }
  .banner_sec .content {
    width: 65%;
    text-align: center;
    position: absolute;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    left: 50%;
    transform: translateX(-50%);
    row-gap: 53px;
  }
  h1{
    /* font-size: 93px; */
    font-size: 4.84375vw;
    /* line-height: 100px; */
    color: var(--white);
    font-weight: 400;
    font-family: var(--primary-font);
    
  }
  .heading2 {
    font-size: 4.6875vw;
    /* font-size: 90px; */
    /* line-height: 100px; */
    color: var(--white);
    font-weight: 400;
    font-family: var(--primary-font);
  }
  .heading3 {
    font-size: 40px;
    line-height: 45px;
    color: #D7D7D7;
    font-family: var(--secondary-font);
    font-weight: 400;
  }
  .heading6 {
    font-size: 18px;
    line-height: 20px;
    color: #D7D7D7;
    font-family: var(--primary-font);
    font-weight: 400;
  }
  .service_sec .content {
    width: 79%;
    margin: 0 auto;
    margin-top: 110px;
  }
  .La-footer .row-gap {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 175px 0; */
    padding: 4.5vw 0;
    gap: 80px;
  }
  .La-footer .row-gap .heading2 {
    font-size: 90px;
    line-height: 100px;
    letter-spacing: 5px;
  }
  .La-footer .copyright_blok{
    border-top: 1px solid #6aa1c3;
    text-align: center;
  }
  .copyright_blok p {
    padding: 8px 0;
    font-size: 20px;
    margin-bottom: 0;
  }
  .testimonial_slider {
    margin: 28px 103px 0 103px;
  }
  .testimonial_slider p {
    font-size: 32px;
    line-height: 45px;
  }
  .author_wrap {
    margin-top: 43px;
    padding-bottom: 30px;
    align-items: center;
  }
  .author_img img {
    width: 100%;
    height: 100%;
  }
  .author_img {
    height: 94px;
    width: 94px;
    background: #333;
    margin-right: 11px;
    border-radius:100%;
    overflow: hidden;
}
.author_wrap h6 {
  font-size: 32px;
  line-height: 45px;
  color: var(--white);
  font-family: var(--primary-font);
    font-weight: 400;
}
.testimonial_slider .slick-prev, .testimonial_slider .slick-next {
  top:100%;
  height: 26px;
    width: 36px;
    transform: translate(0, 0%);  
}
.testimonial_slider .slick-prev {
  left: 40%;
}
.testimonial_slider .slick-next {
  right: 40%;
}
.teatimonial_sec .row-gap {
  margin-top: 40px;
}
.teatimonial_sec .container {
  flex-direction: column;
    justify-content: center;
}
.land-contact-form {
  max-width: 884px;
  margin: 0 auto;
}
.land-contact-form .fill_input, .land-contact-form select {
  border: none;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  outline: none;
  cursor: pointer;
  background: var(--white);
  padding: 20px 20px;
  border-radius: 10px;
  font-family: var(--secondary-font);
    font-weight: 400;
    color:#A9A9A9;
}
.land-contact-form .heading2 {
  margin-bottom: 30px;
}
.land-contact-form .row_gap {
  row-gap: 54px;
}
.feature_sec .react-parallax-content, .contactus_sec .react-parallax-content, 
.technology_sec .react-parallax-content,
.teatimonial_sec .react-parallax-content {
  display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.feature_sec .row_gap {
  row-gap: 80px;
}
.react-parallax-bgimage
{
  object-fit: cover;
}

@media screen and (min-width: 1500px) and (max-width: 1699px){
  .navbar-nav li {
    font-size: 22px;
    line-height: 25px;
  }
  .heading2 {
    line-height: 70px;
  }
  .heading3 {
    font-size: 34px;
    line-height: 40px;
}
p {
  font-size: 20px;
}
.banner_sec .content {
  row-gap: 25px
}
.feature_box img {
  height: 75px;
}
.feature_sec .row_gap .col-lg-4 {
  padding: 0 20px!important;
} 
.testimonial_slider p {
  font-size: 28px;
  line-height: 38px;
}
.testimonial_slider {
  margin: 28px 95px 0 95px;
}
.author_img {
  height: 80px;
  width: 80px;
}
.author_wrap {
  margin-top: 25px;
  padding-bottom: 25px;
  gap: 10px !important;
  
}
.land-contact-form .row_gap {
  row-gap: 40px;
}
.land-contact-form .heading2 {
  margin-bottom: 30px;
}
.La-footer .row-gap .heading2 {
  font-size: 70px;
  line-height: 75px;
  letter-spacing: 4px;
}
}
@media screen and (min-width: 1400px) and (max-width: 1499px){
  .navbar-nav li {
    font-size: 22px;
    line-height: 25px;
  }
  .heading2 {
    line-height: 70px;
  }
  .heading3 {
    font-size: 34px;
    line-height: 40px;
}
p {
  font-size: 20px;
}
.banner_sec .content {
  row-gap: 25px
}
.feature_box img {
  height: 75px;
}
.feature_sec .row_gap .col-lg-4 {
  padding: 0 20px!important;
} 
.testimonial_slider p {
  font-size: 28px;
  line-height: 38px;
}
.testimonial_slider {
  margin: 28px 95px 0 95px;
}
.author_img {
  height: 80px;
  width: 80px;
}
.author_wrap {
  margin-top: 25px;
  padding-bottom: 25px;
  gap: 10px !important;
  
}
.land-contact-form .row_gap {
  row-gap: 40px;
}
.land-contact-form .heading2 {
  margin-bottom: 30px;
}
.La-footer .row-gap .heading2 {
  font-size: 60px;
  line-height: 65px;
  letter-spacing: 3px;
}
}
@media screen and (min-width: 1200px) and (max-width: 1399px){

  .navbar-nav li {
    font-size: 22px;
    line-height: 25px;
  }
  .btns .label-up {
    font-size: 22px;
    line-height: 25px;
  }
  .heading2 {
    line-height: 60px;
  }
  .heading3 {
    font-size: 26px;
    line-height: 30px;
}
p {
  font-size: 17px;
  line-height: 30px;
}
.banner_sec .content {
  row-gap: 25px
}
.feature_sec .row_gap {
  row-gap: 45px;
}
.feature_box img {
  height: 56px;
}
.feature_sec .row_gap .col-lg-4 {
  padding: 0 20px!important;
} 
.testimonial_slider p {
  font-size: 22px;
  line-height: 34px;
}
.testimonial_slider {
  margin: 28px 95px 0 95px;
}
.author_wrap h6 {
  font-size: 24px;
  line-height: 32px;
}
.author_img {
  height: 55px;
  width: 55px;
}
.author_wrap {
  margin-top: 18px;
  padding-bottom: 26px;
  gap: 8px !important;  
}
.testimonial_slider .slick-next {
  right: 38%;
}
.testimonial_slider .slick-prev, .testimonial_slider .slick-next {
  height: 19px;
}
.land-contact-form {
  max-width: 782px;
}
.land-contact-form .row_gap {
  row-gap: 34px;
}
.land-contact-form .heading2 {
  margin-bottom: 30px;
}
.copyright_blok p {
  font-size: 16px;
}
.La-footer .row-gap .heading2 {
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 2px;
}

}
@media screen and (min-width: 992px) and (max-width: 1199px){

  .navbar-nav li {
    font-size: 22px;
    line-height: 25px;
  }
  .btns .label-up {
    font-size: 22px;
    line-height: 25px;
  }
  .heading2 {
    line-height: 46px;
  }
  .heading3 {
    font-size: 26px;
    line-height: 30px;
}
p {
  font-size: 17px;
  line-height: 30px;
}
.banner_sec .content {
  row-gap: 25px
}
.feature_sec .row_gap {
  row-gap: 45px;
}
.feature_box img {
  height: 56px;
}
.feature_sec .row_gap .col-lg-4 {
  padding: 0 20px!important;
} 
.testimonial_slider p {
  font-size: 22px;
  line-height: 34px;
}
.testimonial_slider {
  margin: 28px 95px 0 95px;
}
.author_wrap h6 {
  font-size: 24px;
  line-height: 32px;
}
.author_img {
  height: 55px;
  width: 55px;
}
.author_wrap {
  margin-top: 18px;
  padding-bottom: 26px;
  gap: 8px !important;  
}
.testimonial_slider .slick-next {
  right: 38%;
}
.testimonial_slider .slick-prev, .testimonial_slider .slick-next {
  height: 19px;
}
.land-contact-form {
  max-width: 782px;
}
.land-contact-form .row_gap {
  row-gap: 34px;
}
.land-contact-form .heading2 {
  margin-bottom: 30px;
}
.copyright_blok p {
  font-size: 16px;
}
.La-footer .row-gap .heading2 {
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 2px;
}

}
@media screen and (min-width: 768px) and (max-width: 991px){

  .navbar-nav li {
    font-size: 22px;
    line-height: 25px;
  }
  .btns .label-up {
    font-size: 22px;
    line-height: 25px;
  }
  .heading2 {
    line-height: 46px;
  }
  .heading3 {
    font-size: 26px;
    line-height: 30px;
}
p {
  font-size: 17px;
  line-height: 30px;
}
.banner_sec .content {
  row-gap: 25px
}
.feature_sec .row_gap {
  row-gap: 45px;
}
.feature_box img {
  height: 56px;
}
.feature_sec .row_gap .col-lg-4 {
  padding: 0 20px!important;
} 
.testimonial_slider p {
  font-size: 22px;
  line-height: 34px;
}
.testimonial_slider {
  margin: 28px 20px 0 20px;
}
.author_wrap h6 {
  font-size: 24px;
  line-height: 32px;
}
.author_img {
  height: 55px;
  width: 55px;
}
.author_wrap {
  margin-top: 18px;
  padding-bottom: 26px;
  gap: 8px !important;  
}
.testimonial_slider .slick-next {
  right: 38%;
}
.testimonial_slider .slick-prev, .testimonial_slider .slick-next {
  height: 19px;
}
.land-contact-form {
  max-width: 782px;
}
.land-contact-form .row_gap {
  row-gap: 34px;
}
.land-contact-form .heading2 {
  margin-bottom: 30px;
}
.copyright_blok p {
  font-size: 16px;
}
.La-footer .row-gap {
  text-align: center;
  flex-direction: column;
  padding: 70px 0;
  gap: 20px;
}
.La-footer .row-gap .heading2 {
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 2px;
}
}

@media screen and (max-width: 767px){
  h1, .heading2 {
    font-size: 35px;
    line-height: 40px;
  }

  .btns .label-up {
    font-size: 22px;
    line-height: 25px;
  }

  .heading3 {
    font-size: 26px;
    line-height: 30px;
}
p {
  font-size: 18px;
  line-height: 30px;
}
.banner_sec .content {
  row-gap: 25px;
  width: 90%;
}
.react-parallax-bgimage {
  top: 0;
}
.service_sec .content {
  width: 100%;
}
.service_sec .react-parallax-content, .service_sec .container, .teatimonial_sec .react-parallax-content {
  position: relative; 
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.feature_sec .full_bg {
  padding: 130px 0;
  height: 100%;
}
.feature_sec .row_gap {
  row-gap: 45px;
}
.feature_box img {
  height: 56px;
}
.feature_sec .row_gap .col-lg-4 {
  padding: 0 20px!important;
} 
.teatimonial_sec .container {
  height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.testimonial_slider p {
  font-size: 22px;
  line-height: 34px;
}
.testimonial_slider {
  margin: 28px 20px 0 20px;  
}
.author_wrap h6 {
  font-size: 24px;
  line-height: 32px;
}
.author_img {
  height: 55px;
  width: 55px;
}
.author_wrap {
  margin-top: 18px;
  padding-bottom: 26px;
  gap: 8px !important;  
}
.testimonial_slider .slick-next {
  right: 36%;
}
.testimonial_slider .slick-prev {
  left: 36%;
}
.testimonial_slider .slick-prev, .testimonial_slider .slick-next {
  height: 19px;
}
.land-contact-form {
  max-width: 782px;
}
.land-contact-form .row_gap {
  row-gap: 22px;
}
.land-contact-form .heading2 {
  margin-bottom: 20px;
}
.La-footer .row-gap .heading2 {
  font-size: 54px;
  line-height: 55px;
}
.La-footer .row-gap {
  text-align: center;
  flex-direction: column;
  padding: 70px 0;
  gap: 20px;

}
.copyright_blok p {
  font-size: 16px;
}
.La-footer .row-gap .heading2 {
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 1px;
}
}
@media screen and (max-width: 575px){
.testimonial_slider p {
    font-size: 20px;
    line-height: 29px;
}
.author_wrap h6 {
  font-size: 20px;
  line-height: 26px;
}
.author_img {
  height: 40px;
  width: 40px;
}

}